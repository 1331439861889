// plugins/CouponsPlugin.js

export default {
    namespaced: true,
    actions: {


      // Tus acciones relacionadas con los empresas
      async get_all_Coupons(_, {Authtoken, userCompany}) {
        try {

          // console.log('Authtoken', Authtoken);
          // console.log('sessionUser', sessionUser);
          const API_URL = `${process.env.VUE_APP_BASEURL}coupons`;
          // console.log('userType: ', userType)

          // Create headers object with common headers
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`,
            'User-Company': userCompany
          };
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: headers
          });
      
          const response = await res.json();
      
          console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
    
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },

      async findCoupon(_,couponCode) {
        try {
          const API_URL = `${process.env.VUE_APP_BASEURL}public/find-coupon/${couponCode}`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },

      async findCouponAuto(_,companyId) {
        try {
          const API_URL = `${process.env.VUE_APP_BASEURL}public/find-coupon-auto/${companyId}`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      // ... otras acciones relacionadas con los empresas
    }
  }