import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap'; // Esto importará Bootstrap JavaScript
import '@/assets/styles/styles.scss';
import { createHead } from '@vueuse/head';

const app = createApp(App)

app.use(store)
app.use(router)
const head = createHead();
app.use(head);
app.mount('#app')
