import { createStore } from 'vuex'
import AptReservationsPlugin from '../plugins/AptReservationsPlugin.js'
import CouponsPlugin from '../plugins/CouponsPlugin.js'

export default createStore({
  state: {
    currentStep: 1,
    companyData: null,
    landingPageData: null,
  },
  getters: {
    getCurrentStep(state) {
      return state.currentStep;
    },
    getCompanyData(state) {
      return state.companyData; // Getter para acceder a la respuesta de la API
    },
    getLandingPageData(state) {
      return state.landingPageData;
    },
  },
  mutations: {
    setCurrentStep(state, step) {
      state.currentStep = step;
    },
    setCompanyData(state, data) {
      state.companyData = data; // Mutación para actualizar la respuesta de la API
    },
    setLandingPageData(state, data) {
      state.landingPageData = data;
    },
    clearLandingPageData(state) {
      state.landingPageData = null;
    },
  },
  actions: {
    updateCurrentStep(context, step) {
      context.commit('setCurrentStep', step);
    },
    async fetchCompanyData(context, subdomain) {
      try {
        const response = await fetch(`${process.env.VUE_APP_BASEURL}public/search-company/${subdomain}`);
        if (response.ok) {
          const data = await response.json();
          context.commit('setCompanyData', data);
        } else {
          throw new Error('Company not found');
        }
      } catch (error) {
        context.commit('setCompanyData', null);
      }
    },
    async fetchLandingPageData(context, { slug, companyId }) {
      context.commit('clearLandingPageData');
      try {
        const response = await fetch(`${process.env.VUE_APP_BASEURL}public/search-landing/${slug}?company_id=${companyId}`);
        if (response.ok) {
          const data = await response.json();
          context.commit('setLandingPageData', data.data);
        } else {
          throw new Error('Landing page not found');
        }
      } catch (error) {
        context.commit('setLandingPageData', null);
      }
    },
  },
  modules: {
    AptReservationsStore: AptReservationsPlugin,
    CouponsStore: CouponsPlugin,
  }
})
