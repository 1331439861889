import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';



const routes = [
  {
    path: '/',
    name: 'booking-engine',
    component: () => import('@/views/BookingMain.vue'),
  },
  {
    path: '/landing/:slug',
    name: 'landing-page',
    component: () => import('@/views/LandingPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/NotFound.vue'),
  },
  {
    path: '/landing-not-found',
    name: 'landing-not-found',
    component: () => import('@/views/LandingNotFound.vue'),
  },
  {
    path: '/company-inactive',
    name: 'company-inactive',
    component: () => import('@/views/CompanyInactive.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {

  let subdomain

  if (process.env.VUE_APP_ENVIROMENT === "dev") {
    subdomain = 'traveldesk';
  }else{
    let hostname = window.location.hostname;
    subdomain = hostname.split('.')[0];
  }

  // Evita la comprobación para las rutas 'not-found' y 'company-inactive' para que se carguen directamente
  if (to.name === 'not-found' || to.name === 'company-inactive') {
    next();
    return;
  }

  // Obtiene los datos de la empresa basada en el subdominio
  await store.dispatch('fetchCompanyData', subdomain);
  console.log(store.getters.getCompanyData.data.company_status)

  if (store.getters.getCompanyData.data.company_status === 4) {
    next({ name: 'company-inactive' });
    return;
  } else if (store.getters.getCompanyData.data.company_status > 1 && store.getters.getCompanyData.data.company_status < 4) {
    next({ name: 'not-found' });
    return;
  }

  // console.log('store.getters.getCompanyData', store.getters.getCompanyData.data.company_id);
  
  if (store.getters.getCompanyData) {
    if (to.name === 'landing-page') {
      // Verifica si el slug existe en la tabla marketing_channels
      const companyId = store.getters.getCompanyData.data.company_id;
      const slug = to.params.slug;

      await store.dispatch('fetchLandingPageData', { slug, companyId });

      if (store.getters.getLandingPageData) {
        next();
      } else {
        next({ name: 'landing-not-found' });
      }
    } else {
      next();
    }
  } else {
    next({ name: 'not-found' });
  }
});

export default router;